import { useRef, memo } from "react";

export const Video = memo(({ clipSlug }) => {
  const videoRef = useRef(null);
  const videoUrl = `https://clips.twitch.tv/embed?clip=${clipSlug}&parent=localhost`;

  return (
    <iframe
      allowFullScreen
      className="object-cover h-full w-full shadow-lg"
      ref={videoRef}
      src={videoUrl}
      title={clipSlug}
    />
  );
});
