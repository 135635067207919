import { useParams } from "react-router-dom";
import { Header, Video } from "../../components";

import "./styles.scss";

export const Clip = () => {
  const { clipSlug } = useParams();
  // const videos = [videoId, videoId, videoId, videoId];

  return (
    <>
      <Header />
      <div className="bg-lightbody dark:bg-darkbody flex flex-row px-3 pt-12 min-h-screen justify-center">
        <div className="flex w-screen h-[85vh] max-w-7xl">
          <Video clipSlug={clipSlug} />
        </div>

        {/* <div
        className="bg-gray-900 m-0 h-screen z10 self-center flex-wrap absolute top-0 left-0"
        style={{ width: "calc(90vw - 100px)" }}
      >
        {videos?.map((video, i) => (
          <Screen id={video} key={`${video}_${i}`} />
        ))}
      </div> */}
        {/* <ChatContainer />

      {isModerator ? <ControlBar /> : <Footer />} */}
      </div>
    </>
  );
};
