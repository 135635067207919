import { Header } from "../../components";

import "./styles.scss";

export const Home = () => {
  console.log("asdasdasd");
  return (
    <div className="Home">
      <Header />
      <h1 className="text-3xl font-bold underline">Hello world!</h1>
    </div>
  );
};
